import React from "react";

import {
    Container,
    Box,
    Input,
    Button,
    Typography,
    InputAdornment,
    // Customizable Area Start
    styled,
    Grid,
    Select,
    InputBase,
    MenuItem,
    CircularProgress
    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { NavigateNext, KeyboardArrowDown } from "@material-ui/icons";
import DatePicker from "react-datepicker";
import { calendarLogo } from "./assets";

const today = new Date()

// Customizable Area End

import WorkerCheckpointController, {
    Props,
    configJSON,
} from "./WorkerCheckpointController";

export default class WorkerCheckpoint extends WorkerCheckpointController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start

    CustomInputDate = (
        value: Date | null | undefined, 
        onClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void, 
        onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
    ) => {
        return (
            <Input
                fullWidth={true}
                disableUnderline
                onClick={onClick}
                onChange={onChange}
                data-test-id="targeted_date_test"
                name="date_test"
                placeholder="Select Date"
                value={value}
                defaultValue={""}
                endAdornment={
                    <div>
                        <InputAdornment position="end">
                            <img src={calendarLogo} style={{ width: 28, height: 28, cursor: 'pointer' }} />
                        </InputAdornment>
                    </div>
                }
            />
        );
    }

    checkpointForm = () => {
        const { submitClicked, selectedMeasurementUnit, measurementUnit, totalNumber, compiled, nonCompiled, targetedDate, remarks } = this.state;
        return (
            <Box className="mainBox">
                <Grid md={12} className="mainGrid">
                    <Grid className="inputBlock" md={6} xs={12}>
                        <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Typography className="inputLabel">Unit of Measurement<span>*</span></Typography>
                        </Box>
                        <Select
                            data-test-id="measuremen_unit"
                            className="enterInputField"
                            inputProps={{
                                icon: {
                                    left: 0,
                                },
                            }}
                            variant="outlined"
                            IconComponent={KeyboardArrowDown}
                            value={selectedMeasurementUnit}
                            displayEmpty
                            renderValue={
                                selectedMeasurementUnit
                                    ? undefined
                                    : () => (
                                        <div style={{ color: "#DCDCDC", fontSize: 16 } as React.CSSProperties}>
                                            Unit of Measurement
                                        </div>
                                    )
                            }
                            MenuProps={{
                                anchorOrigin: {
                                    vertical: "bottom",
                                    horizontal: "left",
                                },
                                getContentAnchorEl: null,
                            }}
                            onChange={this.handleChangeMeasurementUnit}
                            input={<InputBase />}
                        >
                            {measurementUnit.map((option) => (
                                <MenuItem key={option.id} value={option.id}>
                                    {option.name}
                                </MenuItem>
                            ))}
                        </Select>
                        <Typography className="errorStyle">
                            {submitClicked && !selectedMeasurementUnit && configJSON.errorMsg}
                        </Typography>
                    </Grid>

                    <Grid className="inputBlock" md={6} xs={12}>
                        <Typography className="inputLabel">Total Number(Target)<span>*</span></Typography>
                        <Input
                            name="totalNumber"
                            data-test-id="total_number"
                            className="enterInputField"
                            value={totalNumber}
                            disableUnderline
                            placeholder="Total Number(Target)"
                            autoComplete="off"
                            onChange={this.handleChangeTotalNumber}
                        />
                        <Typography className="errorStyle">
                            {submitClicked && !totalNumber && configJSON.errorMsg}
                            {totalNumber && !(/^\d*$/.test(totalNumber)) && "Please enter numeric value"}
                        </Typography>
                    </Grid>

                    <Grid className="inputBlock" md={6} xs={12}>
                        <Typography className="inputLabel">Compiled<span>*</span></Typography>
                        <Input
                            name="Compiled"
                            data-test-id="compiled"
                            className="enterInputField"
                            value={compiled}
                            disableUnderline
                            autoComplete="off"
                            placeholder="Compiled(Number)"
                            onChange={this.handleChangeCompiled}
                        />
                        <Typography className="errorStyle">
                            {submitClicked && !compiled && configJSON.errorMsg}
                            {compiled &&  !(/^\d*$/.test(compiled)) && "Please enter numeric value"}
                            {(/^\d*$/.test(compiled)) && parseInt(compiled) > parseInt(totalNumber) && "Compiled number should be less than Total Number(Target)"} 
                        </Typography>
                    </Grid>

                    <Grid className="inputBlock" md={6} xs={12}>
                        <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Typography className="inputLabel">Non-Compiled<span>*</span></Typography>
                        </Box>
                        <Input
                            name="non_compiled"
                            data-test-id="non_compiled"
                            className="enterInputField"
                            value={nonCompiled}
                            disableUnderline
                            placeholder="Non Compiled(Number)"
                            disabled
                        />
                        <Typography className="errorStyle">
                            {submitClicked && !nonCompiled && configJSON.errorMsg}
                        </Typography>
                    </Grid>

                    <Grid className="inputBlock" md={6} xs={12}>
                        <Typography className="inputLabel">Targeted Date<span>*</span></Typography>
                        <DatePicker
                            className="field-input"
                            scrollableYearDropdown
                            showYearDropdown
                            yearDropdownItemNumber={100}
                            dateFormat="dd/MM/yyyy"
                            dateFormatCalendar="MMMM"
                            data-test-id="targeted_date"
                            name="targeted_date"
                            autoComplete="off"
                            placeholderText="Targeted Date"
                            minDate={today}
                            wrapperClassName="date-picker"
                            customInput={this.CustomInputDate(targetedDate, () => {}, this.handleChangeTargetedDate)}
                            popperClassName="example-datepicker-class"
                            selected={targetedDate}
                            onChange={(date) => {
                                this.handleChangeTargetedDatePicker(date);
                            }}
                        />
                        <Typography className="errorStyle">
                            {submitClicked && !targetedDate && configJSON.errorMsg}
                        </Typography>
                    </Grid>

                    <Grid md={6} xs={12}>
                    </Grid>

                    <Grid className="inputBlock" md={6} xs={12}>
                        <Typography className="inputLabel">Remarks<span>*</span></Typography>
                        <textarea
                            name="remarks"
                            data-test-id="remarks"
                            className="remarksTextarea"
                            value={remarks}
                            placeholder="Remarks"
                            onChange={this.handleChangeRemarks}
                        />
                        <Typography className="errorStyle">
                            {submitClicked && !remarks && configJSON.errorMsg}
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
        )
    }
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <>
                <Grid container>
                    <CheckpointWrapper>
                        <Container maxWidth={"xl"}>
                            <Box className="headingWrapper">
                                <Typography className="subHeadingText" data-test-id="navigate_to_audit_screen" onClick={this.navigateToAuditScreen}>Audit</Typography>
                                <Typography className="subHeadingText">
                                    <NavigateNext className="navigateNext" />
                                </Typography>
                                <Typography className="subHeadingText trimWords" data-test-id="navigate_to_macinecheckpoint_screen" onClick={this.navigateTomachinecheckpointScreen}>{this.state.selectedMachineData?.equipment_name}</Typography>
                                <Typography className="subHeadingText">
                                    <NavigateNext className="navigateNext" />
                                </Typography>
                                <Typography className="subHeadingText activeHeadingText trimWords">{this.state.checkpointData?.attributes?.name}</Typography>
                            </Box>
                            <Box className="checkpointTitle">
                                <Typography className="mainHeading trimWords">{this.state.checkpointData?.attributes?.name}</Typography>
                            </Box>

                            {this.checkpointForm()}
                            <Box className="buttonsMainBox">
                                <Box>
                                    <Button
                                        data-test-id="add_btn"
                                        variant="contained"
                                        className="PreviousBtn"
                                        onClick={this.handlePreviousButton}
                                    >
                                        Previous
                                    </Button>
                                </Box>
                                <Box>
                                    <Button
                                        data-test-id="submit_btn"
                                        variant="contained"
                                        className="add-btn"
                                        disabled={this.state.disableSubmitButton}
                                        onClick={this.handleSubmitButton}
                                    >
                                        Submit
                                    </Button>
                                </Box>
                            </Box>
                            {this.state.loading && (
                                <Box data-test-id={"activityLoader"} className="loader-outer">
                                    <Box className="loader-inner">
                                        <CircularProgress />
                                    </Box>
                                </Box>
                            )}
                        </Container>
                    </CheckpointWrapper>
                </Grid>
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
export const CheckpointWrapper = styled(Box)({
    height: "100%",
    padding: "20px 0px",
    width: "100%",
    backgroundColor: "rgb(238, 238, 238)",
    position: "relative",
    boxSizing: "border-box",
    "& .loader-inner": {
        position: "absolute",
        top: "40%",
        left: "50%",
        transform: "translate(-50%, -50%)",
    },
    "& .loader-outer": {
        height: "100%",
        minHeight: "100vh",
        top: 0,
        position: "absolute",
        left: 0,
        bottom: 0,
        right: 0,
        zIndex: 1,
        backgroundColor: "rgb(0, 0, 0, .4)",
    },
    "& .subHeadingText": {
        fontWeight: 700,
        lineHeight: "22px",
        textEdge: "cap",
        fontStyle: "normal",
        cursor: "pointer",
        leadingTrim: "both",
        fontFamily: "ClotherRegular",
        color: "#A1A1A1",
        fontSize: "16px",
    },
    "& .enterInputField": {
        backgroundColor: 'white',
        height: '50px',
        border: '1px solid lightgray',
        fontSie: '18px',
        borderRadius: '8px',
        fontFamily: 'ClotherRegular',
        padding: '10px 15px',
        width: '100%'
    },
    "& .trimWords": {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: "nowrap",
        maxWidth: '100%'
    },
    "& .remarksTextarea": {
        borderRadius: '8px',
        fontFamily: 'ClotherRegular',
        backgroundColor: 'white',
        border: '1px solid lightgray',
        userDrag: 'none',
        fontSize: '18px',
        fontWeight: 700,
        height: '150px',
        padding: '10px 15px',
        resize: 'none',
        width: '100%'
    },
    "& .inputLabel": {
        fontWeight: 600,
        fontFamily: 'ClotherRegular',
        fontSize: '18px',
        color: 'var(--Black, #18181E)',
        marginBottom: '5px'
    },
    "& .navigateNext": {
        color: "#DCDCDC",
    },
    "& .headingWrapper": {
        // gap: "10px",
        display: "flex",
    },
    "& .activeHeadingText": {
        fontWeight: 700,
        color: "#8735E1",
        textEdge: "cap",
        fontSize: "16px",
        fontStyle: "normal",
        leadingTrim: "both",
        lineHeight: "22px",
        fontFamily: "ClotherRegular",
    },
    "& .mainHeading": {
        fontSize: "26px",
        fontWeight: 700,
        color: "#2B2A29",
        textEdge: "cap",
        fontStyle: "normal",
        leadingTrim: "both",
        fontFamily: "ClotherBold",
    },
    "& .Location-input .mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "#8735E1",
    },
    "& .buttonsMainBox": {
        display: "flex",
        justifyContent: "space-between",
        marginTop: "25px",
    },
    "& .noData": {
        textAlign: "center",
        marginTop: "20px",
        fontSize: "18px"
    },
    "& .checkpointTitle": {
        display: "flex",
        margin: "10px 0",
        justifyContent: "space-between",
    },
    "& .field-input": {
        fontFamily: 'ClotherBold',
        backgroundColor: 'white',
        border: '1px solid lightgray',
        borderRadius: '8px',
        height: '50px',
        padding: '10px 15px',
        width: '100%'
    },
    "& .textarea-input, .textarea-input:focus-visible": {
        borderRadius: '8px',
        height: '152px',
        padding: '10px 15px',
        border: '1px solid lightgray',
        fontFamily: 'ClotherBold',
        outline: 'none',
        backgroundColor: 'white',
        width: '100%',
    },
    "& .Location-input .MuiInputBase-input": {
        height: "14px",
    },
    "& .MuiInputBase-input": {
        height: "15px",
    },
    "& .MuiSelect-select:focus": {
        backgroundColor: '#fff'
    },
    "& .submit-button": {
        display: "flex",
        justifyContent: "end",
        marginBottom: "10px",
        width: "100%",
    },
    "& .MuiInputBase-root": {
        fontFamily: "ClotherBold",
    },
    "& .MuiOutlinedInput-root": {
        borderRadius: "8px"
    },
    "& .errorStyle": {
        fontSize: "0.85rem",
        color: "#F30202",
        marginBottom: "0.5rem",
    },
    "& .react-datepicker-wrapper": {
        display: 'flex',
    },
    "& .inputBlock": {
        marginBottom: "10px",
        maxWidth: '48%',
        "@media (max-width: 600px)": {
            maxWidth: '100%',
        }
    },
    "& .mainGrid": {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        padding: '20px',
        flexWrap: 'wrap',
        "@media (max-width: 600px)": {
            flexDirection: 'column'
        }
    },
    "& .mainBox": {
        margin: "15px 0px",
        borderRadius: "8px",
        backgroundColor: 'white',
        width: "100%",
    },
    "& .add-btn": {
        width: "160px",
        height: "50px",
        color: "#FFF",
        padding: "10px",
        textAlign: "center",
        borderRadius: "8px",
        background: "#8735E1",
        boxShadow: "none",
        fontFamily: "ClotherBold",
        fontSize: "20px",
        fontStyle: "normal",
        fontWeight: 700,
        textTransform: "capitalize",
    },
    "& .PreviousBtn": {
        background: "#FFF",
        padding: "10px",
        textAlign: "center",
        color: "#8735E1",
        borderRadius: "8px",
        border: "1px solid #8735E1",
        width: "160px",
        height: "50px",
        boxShadow: "none",
        fontFamily: "ClotherBold",
        fontSize: "20px",
        fontStyle: "normal",
        fontWeight: 700,
        textTransform: "capitalize",
    },
    "& *::-ms-input-placeholder": {
        color: "#A8A0A0 !important",
        fontSize: "16px"
    },
    "& *::placeholder": {
        color: "#DCDCDC !important",
        fontSize: "18px",
        fontWeight: 400
    },
    "& input::placeholder": {
        color: "#A8A0A0 !important",
        fontSize: "16px"
    },
});

// Customizable Area End
