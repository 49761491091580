import React from "react";

// Customizable Area Start
import {
  Typography,
  Box,
  Button,
  styled,
  Grid,
  Select,
  MenuItem,
  CircularProgress,
  InputBase,
} from "@material-ui/core";
import { webStyles } from "../../../components/src/styles";
import { KeyboardArrowDown, NavigateNext } from "@material-ui/icons";
// Customizable Area End

import CoursesAnalyticsController, {
  DropdownValues,
  Props,
} from "./CoursesAnalyticsController.web";
import CustomMessage from "../../../components/src/CustomMessage.web";

export default class CoursesAnalytics extends CoursesAnalyticsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  filters = () => {
    const { country, selectedCountry, cities, selectedCities, plants, selectedPlants, departments, selectedDepartments, allWorkLocation, selectedAllWorkLocation, roles, selectedRole } = this.state;
    return (
      <Grid container spacing={1} className="dropDownRowMargin">
        <Grid item xs={12} sm={6} md={4} lg={2} className="dropDownWidth">
          <Select
            labelId="country_dropdown"
            id="country_dropdown"
            data-test-id="country_dropdown"
            IconComponent={KeyboardArrowDown}
            variant="outlined"
            displayEmpty
            value={selectedCountry}
            className="enterInputField"
            onChange={(event) => this.handleChangeCountry(event.target.value)}
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
              getContentAnchorEl: null,
            }}
            renderValue={(selected: unknown) => {
              if ((selected as DropdownValues[]).length === 0) {
                return (
                  <div style={{ color: "#A1A1A1", fontSize: 16 } as React.CSSProperties}>
                    Country
                  </div>);
              }
              return this.renderSelectedValues(selectedCountry);
            }}
            input={<InputBase />}
          >
            {country.map((option) => (
              <MenuItem
                style={this.highLightSelectedValues(selectedCountry, option.id) ? webstyle.selectedValue : webstyle.nonSelectedValue}
                key={option.id} value={option.id}
              >
                {option.attributes.name}
              </MenuItem>
            ))}
          </Select>
        </Grid>

        <Grid item xs={12} sm={6} md={4} lg={2} className="dropDownWidth">
          <Select
            labelId="city_dropdown"
            id="city_dropdown"
            data-test-id="city_dropdown"
            IconComponent={KeyboardArrowDown}
            variant="outlined"
            displayEmpty
            value={selectedCities}
            className="enterInputField"
            onChange={(event) => this.handleChangeCity(event.target.value)}
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
              getContentAnchorEl: null,
            }}
            renderValue={(selected: unknown) => {
              if ((selected as DropdownValues[]).length === 0) {
                return (
                  <div style={{ color: "#A1A1A1", fontSize: 16 } as React.CSSProperties}>
                    Location
                  </div>);
              }
              return this.renderSelectedValues(selectedCities);
            }}
            input={<InputBase />}
          >
            {cities.map((option) => (
              <MenuItem
                style={this.highLightSelectedValues(selectedCities, option.id) ? webstyle.selectedValue : webstyle.nonSelectedValue}
                key={option.id} value={option.id}
              >
                {option.attributes.name}
              </MenuItem>
            ))}
          </Select>
        </Grid>

        <Grid item xs={12} sm={6} md={4} lg={2} className="dropDownWidth">
          <Select
            labelId="plant_dropdown"
            id="plant_dropdown"
            data-test-id="plant_dropdown"
            IconComponent={KeyboardArrowDown}
            variant="outlined"
            displayEmpty
            value={selectedPlants}
            className="enterInputField"
            onChange={(event) => this.handleChangePlant(event.target.value)}
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
              getContentAnchorEl: null,
            }}
            renderValue={(selected: unknown) => {
              if ((selected as DropdownValues[]).length === 0) {
                return (
                  <div style={{ color: "#A1A1A1", fontSize: 16 } as React.CSSProperties}>
                    Function
                  </div>);
              }
              return this.renderSelectedValues(selectedPlants);
            }}
            input={<InputBase />}
          >
            {plants.map((option) => (
              <MenuItem
                style={this.highLightSelectedValues(selectedPlants, option.id) ? webstyle.selectedValue : webstyle.nonSelectedValue}
                key={option.id} value={option.id}
              >
                {option.attributes.name}
              </MenuItem>
            ))}
          </Select>
        </Grid>

        <Grid item xs={12} sm={6} md={4} lg={2} className="dropDownWidth">
          <Select
            labelId="department_dropdown"
            id="department_dropdown"
            data-test-id="department_dropdown"
            IconComponent={KeyboardArrowDown}
            variant="outlined"
            displayEmpty
            value={selectedDepartments}
            className="enterInputField"
            onChange={(event) => this.handleChangeDepartment(event.target.value)}
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
              getContentAnchorEl: null,
            }}
            renderValue={(selected: unknown) => {
              if ((selected as DropdownValues[]).length === 0) {
                return (
                  <div style={{ color: "#A1A1A1", fontSize: 16 } as React.CSSProperties}>
                    Sub-Function
                  </div>);
              }
              return this.renderSelectedValues(selectedDepartments);
            }}
            input={<InputBase />}
          >
            {departments.map((option) => (
              <MenuItem
                style={this.highLightSelectedValues(selectedDepartments, option.id) ? webstyle.selectedValue : webstyle.nonSelectedValue}
                key={option.id} value={option.id}
              >
                {option.attributes.name}
              </MenuItem>
            ))}
          </Select>
        </Grid>

        <Grid item xs={12} sm={6} md={4} lg={2} className="dropDownWidth">
          <Select
            labelId="all_dropdown"
            id="all_dropdown"
            data-test-id="all_dropdown"
            IconComponent={KeyboardArrowDown}
            variant="outlined"
            displayEmpty
            value={selectedAllWorkLocation}
            className="enterInputField"
            onChange={(event) => this.handleChangeAllWorkLocation(event.target.value)}
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
              getContentAnchorEl: null,
            }}
            renderValue={(selected: unknown) => {
              if ((selected as DropdownValues[]).length === 0) {
                return (
                  <div style={{ color: "#A1A1A1", fontSize: 16 } as React.CSSProperties}>
                    Work Location
                  </div>);
              }
              return this.renderSelectedValues(selectedAllWorkLocation);
            }}
            input={<InputBase />}
          >
            {allWorkLocation.map((option) => (
              <MenuItem
                style={this.highLightSelectedValues(selectedAllWorkLocation, option.id) ? webstyle.selectedValue : webstyle.nonSelectedValue}
                key={option.id} value={option.id}
              >
                {option.attributes.name}
              </MenuItem>
            ))}
          </Select>
        </Grid>

        <Grid item xs={12} sm={6} md={4} lg={2} className="dropDownWidth">
          <Select
            labelId="role_dropdown"
            id="role_dropdown"
            data-test-id="role_dropdown"
            IconComponent={KeyboardArrowDown}
            variant="outlined"
            displayEmpty
            value={selectedRole}
            className="enterInputField"
            onChange={(event) => this.handleChangeRole(event.target.value)}
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
              getContentAnchorEl: null,
            }}
            renderValue={(selected: unknown) => {
              if ((selected as DropdownValues[]).length === 0) {
                return (
                  <div style={{ color: "#A1A1A1", fontSize: 16 } as React.CSSProperties}>
                    Roles
                  </div>);
              }
              return this.renderSelectedValues(selectedRole);
            }}
            input={<InputBase />}
          >
            {roles.map((option) => (
              <MenuItem
                style={this.highLightSelectedValues(selectedRole, option.id) ? webstyle.selectedValue : webstyle.nonSelectedValue}
                key={option.id} value={option.id}
              >
                {option.attributes.name}
              </MenuItem>
            ))}
          </Select>
        </Grid>

        <Grid item xs={12} sm={6} md={4} lg={2} className="dropDownWidth">
          <Button
            data-test-id="search_button"
            variant="contained"
            className="SearchButtonStyle"
            onClick={this.handleSearch}
          >
            Search
          </Button>
        </Grid>
      </Grid >
    );
  };

  assignedCourses = () => {
    const columnTitles = [
      {
        id: 1,
        title: "Sr No",
        width: '8%',
      },
      {
        id: 2,
        title: "Title",
        width: '47%',
      },
      {
        id: 3,
        title: "Category",
        width: '15%',
      },
      {
        id: 4,
        title: "Progress",
        width: '15%',
      },
      {
        id: 5,
        title: "Due Date",
        width: '15%',
      }
    ];
    const coursesData: any = this.state.coursesAnalytics;
    return (
      <Grid container justifyContent="center" spacing={1}>
        <Grid item xs={12} className="analyticTitle analyticFilterTitle" justifyContent="space-between">
          <Typography component={"h2"} className="mainHeading">Assigned Courses </Typography>
          <Typography component={"p"} className="seeAllStyle">See All </Typography>
        </Grid>
        <Grid xs={12} className="tableScroll" item>
          <Box className="tableStyles">
            <Box className="rowStyle">
              {columnTitles.map((item, index) => (
                <Box key={item.id} width={item.width} className={`headerCellStyle ${index == 0 ? ' first' : ''}`}>{item.title}</Box>
              ))}
              <Box className="headerCellStyle last"></Box>
            </Box>
            {coursesData.length > 0 && coursesData.map((row: any, index: number) => (
              <Box key={index} className="rowStyle">
                <Box className="cellStyle trimWords">{index + 1 + '.'}</Box>
                <Box className="cellStyle trimWords">{row.title}</Box>
                <Box className="cellStyle trimWords">{row.category}</Box>
                <Box className="cellStyle trimWords">{row.completed_courses + '/' + row.total_courses}</Box>
                <Box className="cellStyle trimWords">{row.due_date}</Box>
              </Box>
            ))}
          </Box>
        </Grid>
        {coursesData.length === 0 &&
          <Box width={"99%"} marginTop={"-20px"} justifyContent={"center"} alignItems={"center"} height={"100%"} overflow={"hidden"}>
            <CustomMessage message="No data available." boxHeight="200px" />
          </Box>
        }
      </Grid>
    )
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
        <Grid className="firstGridContainer" container>
          <Wrapper>
            <Box style={webStyles.breadcrumbWrapper}>
              <Typography style={webStyles.breadcrumbItem}>Analytics</Typography>
              <NavigateNext style={webStyles.navigateIcon} />
              <Typography style={webStyles.breadcrumbActiveItem}>Course Analytics</Typography>
            </Box>

            <Box className="analyticTitle">
              <Typography className="mainHeading">Course Analytics</Typography>

              {!this.state.loading && !this.state.isWorker &&
                <Box>
                  <Button
                    variant="contained"
                    data-test-id="goto-action-tracker-analytics"
                    onClick={() => this.goToActionTrackerAnalytics()}
                    className="courseAnalyticsButton"
                  >
                    Action Tracker
                  </Button>
                </Box>
              }
            </Box>
            {!this.state.loading && !this.state.isWorker && <Box>
              {this.filters()}
              {this.assignedCourses()}
            </Box>}
            {this.state.loading && (
              <Grid justifyContent="center" container>
                <CircularProgress className="loader" />
              </Grid>
            )}
          </Wrapper>
        </Grid>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export const Wrapper = styled(Box)({
  backgroundColor: "rgb(238, 238, 238)",
  width: "100%",
  boxSizing: "border-box",
  padding: "30px",
  overflowY: "auto",
  height: "100%",
  position: "relative",
  overflowX: "hidden",
  "& .loader": {
    color: "#8735e1"
  },
  "& .analyticFilterTitle": {
    margin: "30px 0px 10px 0px",
  },
  "& .analyticTitle": {
    justifyContent: "space-between",
    margin: "16px 0px",
    display: "flex",
  },
  "& .mainHeading": {
    leadingTrim: "both",
    color: "#2B2A29",
    fontSize: "26px",
    textEdge: "cap",
    fontWeight: 700,
    fontStyle: "normal",
    fontFamily: "ClotherBold",
    width: "300px",
    lineHeight: "25px",
  },
  "& .SearchButtonStyle": {
    boxShadow: "none",
    color: "#8735E1",
    padding: "5px 10px 10px 10px",
    textAlign: "center",
    backgroundColor: "#FFF",
    border: "1px solid #8735E1",
    borderRadius: "8px",
    height: "48px",
    width: "100%",
    fontSize: "20px",
    fontFamily: "ClotherBold",
    fontWeight: 700,
    textTransform: "capitalize",
    fontStyle: "normal",
  },
  "& .enterInputField": {
    backgroundColor: 'white',
    fontFamily: 'ClotherRegular',
    fontSie: '18px',
    border: '1px solid lightgray',
    height: '50px',
    borderRadius: '8px',
    width: "100%",
    padding: '10px',
  },
  "& .MuiSelect-select:focus": {
    backgroundColor: "white"
  },
  "& .MuiSelect-outlined.MuiSelect-outlined": {
    marginBottom: "6px",
    paddingRight: "16px",
  },
  "& .tableStyles": {
    width: '100%',
    backgroundColor: 'white',
    display: 'table',
  },
  "& .rowStyle": {
    display: 'table-row'
  },
  "& .headerCellStyle": {
    backgroundColor: "#8735E1",
    display: 'table-cell',
    padding: '10px',
    color: 'white',
    fontFamily: "ClotherRegular",
    textAlign: 'left',
    fontWeight: 700,
    fontSize: "16px",
  },
  "& .tableScroll": {
    overflowX: "auto"
  },
  "& .headerCellStyle.last": {
    borderRadius: "0px 8px 0px 0px"
  },
  "& .headerCellStyle.first": {
    borderRadius: "8px 0px 0px 0px"
  },
  "& .cellStyle": {
    display: 'table-cell',
    textAlign: 'left',
    padding: '10px',
    fontWeight: 400,
    fontSize: "14px",
  },
  "& .trimWords": {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    maxWidth: '100%',
    whiteSpace: "nowrap",
  },
  "& .firstGridContainer": {
    height: "100%",
  },
  "& .dropDownRowMargin": {
    marginBottom: 16
  },
  "& .dropDownWidth": {
    width: "10%",
    minWidth: "150px",
  },
  "& .MuiSelect-iconOutlined": {
    color: "#DCDCDC"
  },
  "& .courseAnalyticsButton": {
    padding: "10px 10px 14px 10px",
    boxShadow: "none",
    borderRadius: "8px",
    textAlign: "center",
    background: "#FFFFFF",
    color: "#8735E1",
    height: "35px",
    width: "auto",
    fontSize: "20px",
    fontFamily: "ClotherBold",
    fontWeight: 700,
    fontStyle: "normal",
    border: "1px solid #8735E1",
    marginRight: "15px",
    textTransform: "capitalize",
  },
  "& .seeAllStyle": {
    fontWeight: 700,
    fontSize: "12px",
    color: "#8735E1",
    cursor: "pointer",
    lineHeight: "24px",
  }
})
const webstyle = {
  nonSelectedValue: {
    color: "#888888",
    background: "#FFF",
  },
  selectedValue: {
    color: "#2B2A29",
    background: "#EEDEFF",
  },
  optionValueStyle: {
    color: '#A1A1A1',
    fontSize: '16px'
  }
}
// Customizable Area End
