import React from "react";
// Customizable Area Start
import {
  Box,
  Button,
  Grid,
  List,
  ListSubheader,
  CircularProgress,
  createTheme,
  ThemeProvider,
  styled,
  Input,
  InputAdornment,
  Typography,
  IconButton,
  Dialog,
  Avatar,
  Menu,
  MenuItem
} from "@material-ui/core";
import Story2 from "../../story2/src/Story2.web";
import PostCreationPopUp from "./postCreationPopUp/PostCreationPopUp.web";
import { webStylesLocal } from "./ReportPostModal.web";
import Loader from "../../../components/src/Loader.web";
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { location, comment, share, searchDefaultImage, like, Unlike, attachment1, attachment2, time, addImg, checkImg, SuccessImage, refreshImg } from "./assets";
import { Pagination } from "@material-ui/lab";

const theme = createTheme({
  typography: {
    h6: {
      fontWeight: 500,
      Font: "Clother"
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
  overrides: {
    MuiPaper: {
      root: {
        boxShadow: 'none'
      }
    }
  }
});
import { InputField, webStyle } from "./postCreationPopUp/styles";
import { getTimeFromNow } from "../../../components/src/helpers/getTimeFromNow";
import { PostData } from "./ProfileController.web";
import {
  CommentData,
  LocationUserData,
  TagsData,
  configJSON,
} from "./DashboardController.web";
import { profileIcon } from "../../../components/src/assets";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CustomMessage from "../../../components/src/CustomMessage.web";
// Customizable Area End
import DashboardController, {
  Props,
  webConfigJSON
} from "./DashboardController.web";

export default class Dashboard extends DashboardController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.setShowPostPopUp = this.setShowPostPopUp.bind(this)
    // Customizable Area End
  }
  // Customizable Area Start
  componentDidUpdate(prevProps: any, prevState: { showPostPopUp: boolean; }) {
    this.state.showPostPopUp &&
      prevState.showPostPopUp !== this.state.showPostPopUp &&
      this.getPostSettings();
  }

  setShowPostPopUp = (value: boolean) => {
    this.setState({ postDescription: '', showAiTag: false, loading: false, aiImageprocessing: false, showPostPopUp: value });
  }

  searchForPostAndUser = () => {
    return (
      <>
        <WrapperSearch>
          <Input
            type="search"
            data-test-id="search_text"
            placeholder="Search"
            className="searchTextField"
            disableUnderline
            value={this.state.searchText}
            startAdornment={
              !this.state.searchVisible ? (
                <div className="searchIconStyle">
                  <InputAdornment position="start">
                    <SearchIcon
                      height="20px"
                      width="20px"
                    />
                  </InputAdornment>
                </div>
              ) : null
            }
            endAdornment={
              this.state.searchVisible ? (
                <div data-test-id="clear_search"
                  className="closeIconStyle" onClick={this.handleClearSearch} style={webStyles.cursorPoint}>
                  <InputAdornment position="end">
                    <CloseIcon
                      height="20px"
                      width="20px"
                      style={webStyles.closeIcon}
                    />
                  </InputAdornment>
                </div>
              ) : null
            }
            onChange={this.handleChangeSearch}
            onKeyUp={this.handleSearchKeyUp}
          />
        </WrapperSearch>
        {this.state.searchVisible && this.postAndUserButtons()}
      </>
    )
  }

  postAndUserButtons = () => {
    return (
      <ButtonWrapper>
        <>
          <Button
            data-test-id="posts_button"
            className={`buttonStyle ${this.state.activeButton === "Posts" ? "activeButtonStyle" : ""}`}
            onClick={this.handlePostsClick}
          >
            Posts
          </Button>
          <Button
            data-test-id="users_button"
            className={`buttonStyle ${this.state.activeButton === "Users" ? "activeButtonStyle" : ""}`}
            onClick={this.handleUsersClick}
          >
            Users
          </Button>
        </>
        {this.state.activeButton === "Posts" && this.postsResult()}
        {this.state.activeButton === "Users" && !this.state.isLoading && this.usersResult()}
      </ButtonWrapper>
    )
  }

  getUserLocation = (user: LocationUserData) => {
    const location = user.attributes?.location_name;
    const workLocation = user.attributes?.work_location_name;

    return (location != null || workLocation != null ?
      <>
        {(location != null && location.slice(0, 10))}
        {(location != null && workLocation != null && `, `)}
        {(workLocation != null && workLocation.slice(0, 8))}
      </> : "No Data Found");
  }

  usersResult = () => {
    const { userSearchResult, totalCountForUser } = this.state;
    const userFound = totalCountForUser <= 1 ? ' User Found' : ' Users Found';
    return (
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Typography className="foundText">{totalCountForUser} {" "} {userFound}</Typography>
        </Grid>
        <Grid item xs={12} className="contentBox">
          {userSearchResult.map((user, index) => {
            const profilePic = user.attributes?.profile_image.length ? user.attributes?.profile_image : searchDefaultImage
            return (<>
              <Grid container className="mainContainerGrid" alignItems="center" spacing={1}>
                <Grid item xs={12} sm={8}>
                  <Grid style={webStyle.pointerClass} container alignItems="center" data-test-id={`user-names[${index}]`} onClick={() => this.goToUserProfile(user?.id)}>
                    <Grid item style={webStyles.imageStyles}>
                      <img src={profilePic} className="imageStyle" />
                    </Grid>
                    <Grid item className="trimWordsUser">
                      <Box className="userNameText">{user.attributes?.full_name}</Box>
                      <Box className="userNameText">{user.attributes?.role_name}</Box>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Box className="rightImageContainer flexCenter">
                    <img src={location} />
                    <Typography className="locationText">
                      {this.getUserLocation(user)}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </>)
          })}
        </Grid>
        <Grid item xs={12}>
          <Box className="flexEnd">
            {totalCountForUser >= 1 &&
              <Pagination
                count={Math.ceil(this.state.totalCountForUser / 10)}
                page={this.state.currentPage}
                variant="outlined"
                style={webStyles.pagination}
                onChange={this.handlePageChange}
                data-test-id="handlePageChange"
              />}
          </Box>
        </Grid>
      </Grid>
    )
  }

  postsResult = () => {
    const { postSearchResult, totalCountForPost } = this.state;
    const postfound = totalCountForPost <= 1 ? ' Post Found' : ' Posts Found';

    return (
      <>
        {postSearchResult.length > 0 ?
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <Typography className="foundText">{totalCountForPost}{" "}{postfound}</Typography>
            </Grid>
            <Grid item xs={12}>
              {postSearchResult.map((post, index) => {
                const profilePic = post.attributes?.profile_pic || searchDefaultImage
                return (<Box className="postContentBox">
                  <Grid container alignItems="center" style={webStyles.postContentBox}>

                    <Grid item xs={11}>
                      <Box data-test-id={`user-name[${index}]`} className="flexCenter" onClick={() => this.goToUserProfile(post.attributes?.account_id)}>
                        <img src={profilePic} className="PostTitleImageStyle" />
                        <Box className="postTitleText trimWords">{post.attributes?.user_name} Created this...</Box>
                      </Box>
                    </Grid>

                    <Grid item xs={1}>
                      <Box className="flexEnd">
                        <IconButton>
                          <MoreVertIcon style={webStyles.moreIcon} />
                        </IconButton>
                      </Box>
                    </Grid>

                    <Grid item xs={12}>
                      <Typography className="postDescription">{post.attributes?.description}</Typography>
                    </Grid>

                    <Grid item xs={12} sm={4}>
                      <Box style={webStyles.postLocationBox} className="trimWords postLocationContainer flexCenter">
                        <img src={location} />
                        <Typography className="locationText trimWords">
                          {post.attributes?.work_location ? post.attributes?.work_location : "Data Not Avilable"}
                        </Typography>
                      </Box>
                    </Grid>

                    <Grid item xs={12}>
                      <Grid container style={webStyles.postLocationBox} spacing={1} alignItems="center" justifyContent="space-between">
                        <Grid item xs={12} sm={6}>
                          <Grid container alignItems="center" spacing={1}>
                            <Grid item xs={4} className="postNameText">
                              <Box className="flexCenter" data-test-id={`user-name2[${index}]`} onClick={() => this.goToUserProfile(post.attributes?.account_id)}>
                                <img src={profilePic} className="PostBelowImageStyle postIconstyle" />
                                <Typography className="trimWords">{post.attributes?.user_name}</Typography>
                              </Box>
                            </Grid>
                            <Grid item xs={6} className="postNameText">
                              <Box className="flexCenter">
                                <AccessTimeIcon className="postIconstyle" />
                                {this.timetoSince(post.attributes?.created_at)}
                              </Box>
                            </Grid>
                          </Grid>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          <Grid container alignItems="center" spacing={1} direction="row-reverse">
                            <Grid item className="postNameText">
                              <Box className="flexCenter" style={webStyle.pointerClass} data-test-id={`comments[${index}]`} onClick={() => this.showSearchPostComments(post, index)}>
                                <img src={comment} className="postIconstyle" />
                                {post?.attributes?.comments_count} {webConfigJSON.CommentsText}
                              </Box>
                            </Grid>
                            <Grid item className="postNameText">
                              <Box className="flexCenter">
                                <img src={share} className="postIconstyle" />
                                0 Share
                              </Box>
                            </Grid>
                            <Grid item className="postNameText">
                              {
                                post.attributes.commentLikeLoader ?
                                  <CircularProgress />
                                  :
                                  <Box style={webStyle.pointerClass} className="flexCenter" onClick={() => this.proceedToLike(post.attributes)} data-test-id={`like-btn2${index}`}>
                                    {
                                      post.attributes.did_you_like ?
                                        <img src={like} />
                                        :
                                        <img src={Unlike} />
                                    }
                                    <Typography style={webStyle.likeText}>{post?.attributes?.likes_count} {webConfigJSON.LikesText}</Typography>
                                  </Box>
                              }
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                      {post?.attributes?.openCommentsList && (
                        <>
                          <Typography>Comments ({this.state.totalComment})</Typography>
                          {this.state.comments.map((data) => {
                            return (
                              <Box style={webStyles.commentList} key={data.id}>
                                <Grid container justifyContent="space-between">
                                  <Grid item lg={8} container>
                                    <Avatar src={data?.attributes?.account?.profile_image || profileIcon} style={webStyle.commentImage} />
                                    <Typography style={webStyle.commentUserName}>{data?.attributes?.account?.full_name}</Typography>
                                    <Typography style={webStyle.commentTimeText}>
                                      <div style={webStyle.timeDotIcon}></div>
                                      {this.timetoSince(data?.attributes?.created_at)}
                                    </Typography>
                                  </Grid>
                                  <Grid item lg={4} style={webStyles.reportGrid}>
                                    <MoreVertIcon style={webStyle.pointerClass} data-test-id={`report[${index}]`} onClick={(event) => this.showReportComment(event, data?.id)}></MoreVertIcon>
                                    {this.commentReportMenu(data, index, 'search')}
                                  </Grid>
                                </Grid>
                                <Typography style={webStyle.commentValue}>{data?.attributes?.comment}</Typography>
                              </Box>
                            )
                          })}
                          {this.state.commentsloading &&
                            <CircularProgress />}
                        {this.state.commentsloading &&
                            <Typography data-test-id={`load-more3${index}`} onClick={() => this.getComment(post?.attributes?.id)} align="center" style={webStyle.loadMore}>Load More
                              <img src={refreshImg} style={webStyles.loadMoreIcon} />
                            </Typography>}
                        </>
                      )
                      }
                    </Grid>
                  </Grid >
                </Box >)
              })}
            </Grid >
            <Grid item xs={12}>
              <Box display="flex" justifyContent="flex-end">
                {totalCountForPost >= 1 &&
                  <Pagination
                    count={Math.ceil(this.state.totalCountForPost / 10)}
                    page={this.state.currentPage}
                    variant="outlined"
                    onChange={this.handlePageChange}
                    data-test-id="handlePageChange"
                  />}
              </Box>
            </Grid>
          </Grid >
          :
          <>
            {!this.state.isLoading &&
              <CustomMessage message="No posts found, please try again." />
            }
          </>
        }
      </>
    )
  }

  commentReportMenu = (data: CommentData, index: number, dataTestText: string) => {
    return (
      <Menu
        id="basic-menu"
        anchorEl={this.state.anchorElReportComment}
        open={this.state.reportCommentOption}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        onClose={() => this.showReportComment({ currentTarget: null }, 0)}
        className='reportmenu'
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
        data-test-id={`menu-btn${dataTestText}${index}`}
        style={webStyles.reportMenuWidth}
      >
        <MenuItem
          data-test-id={`report-comment-btn${index}`}
          style={webStyles.reportMenuItem}
          onClick={() => this.handleOpenReportCommentModal(data.id)}
        >
          {configJSON.ReportText}
        </MenuItem>
      </Menu>
    )
  }

  reportPostMenu = (postData: PostData, index: number) => {
    return (
      <Menu
        id="basic-menu"
        anchorEl={this.state.anchorEl}
        open={this.state.openPostOption}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        onClose={() => this.handlePostOption({ currentTarget: null }, postData)}
        className='reportmenu'
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
        data-test-id={`menu-btn${index}`}
        style={webStyles.reportMenuWidth}
      >
        <MenuItem
          data-test-id={`report-btn${index}`}
          style={webStyles.reportMenuItem}
          onClick={() => this.handleReportForPost()}>
          {configJSON.ReportText}
        </MenuItem>
      </Menu>
    )
  }

  postLikeFlow = (postData: PostData, index: number) => {
    return (
      <Box display={"flex"} alignItems={"center"}>
        {
          this.state.commentLikeLoader && this.state.currentpostId === postData.attributes.id ?
            <CircularProgress />
            :
            <>
              <IconButton style={webStyles.iconCustomPadding} onClick={() => this.proceedToLike(postData?.attributes)} data-test-id={`like-btn${index}`}>
                <img src={postData.attributes.did_you_like ? like : Unlike} />
              </IconButton>
              <Typography variant="body2" component="div" style={webStyles.userName}>
                {postData.attributes.likes_count} {configJSON.LikesText}
              </Typography>
            </>
        }
      </Box>
    )
  }

  addCommentFlow = (index: number, postData: PostData) => {
    return (
      <>
        {
          this.state.addCommentsLoader && postData?.attributes?.id === this.state.currentpostId ?
            <Box display={"flex"} alignItems={"center"} justifyContent={"center"} width='100%'>
              <CircularProgress />
            </Box>
            :
            <Box display={"flex"} alignItems={"center"} mt={1} width='100%' position='relative'>
              <InputField
                placeholder="Write Your Comment"
                variant='outlined'
                value={postData?.attributes?.id === this.state.currentpostId ? this.state.postComment : ''}
                onFocus={() => this.setCurrentPostId(postData?.attributes?.id)}
                onChange={(event: { target: { value: string; }; }) => this.proceedToAddComment({ keyCode: 12, target: { value: event.target.value } }, postData?.attributes?.id)}
                onKeyDown={(event: { keyCode: number; }) => this.proceedToAddComment({ keyCode: event.keyCode, target: { value: this.state.postComment } }, postData?.attributes?.id)}
                data-test-id={`comment-btn${index}`}
              />
              <IconButton style={webStyles.iconBtn as React.CSSProperties}>
                <Button
                  component="label"
                  style={{ ...webStyle.inputAttachment, ...webStyle.uploadAttachment } as React.CSSProperties}
                >
                  <div style={webStyle.attachmentBox as React.CSSProperties}>
                    <img src={attachment1} style={webStyles.attachments} />
                    <img src={attachment2} />
                  </div>
                  <input
                    type="file"
                    name="image"
                    id="user-image"
                    accept="image/png, image/jpeg"
                  />
                </Button>
              </IconButton>
            </Box>
        }
      </>
    )
  }

  postCard = (postData: PostData, index: number) => {
    return (
      <Box
        test-id='post-view'
        display="flex"
        flexDirection="column"
        bgcolor="white"
        p={2}
        borderRadius={8}
        border='1px solid #d7d2d2'
        boxShadow={1}
        mt='0.6rem'
        pt={2}
      >
        <Box display="flex" alignItems="center" justifyContent="space-between" >
          <Box style={webStyle.pointerClass} display="flex" alignItems="center" data-test-id={`user-names${index}`} className="flexCenter" onClick={() => this.goToUserProfile(postData.attributes?.account_id)}>
            <Avatar
              data-test-id='profile-icon'
              src={postData?.attributes?.profile_pic || searchDefaultImage}
              alt="Custom Icon"
              style={webStyles.customIcon}
            />
            <Typography
              data-test-id='post-heading'
              variant="h6"
              style={webStyles.postUserName}
              component="div">
              {postData?.attributes?.user_name || configJSON.NoNameText} {configJSON.createdText}
            </Typography>
          </Box>
          <IconButton onClick={(event) => this.handlePostOption(event, postData)} data-test-id={`handlePostOption${index}`}>
            <MoreVertIcon />
          </IconButton>
          {this.reportPostMenu(postData, index)}
        </Box >
        {(postData?.attributes?.output_image_url || postData?.attributes?.image_url) &&
          <a href={postData.attributes.output_image_url ? postData.attributes.output_image_url : postData.attributes.image_url} target='blank'>
            <img
              src={postData.attributes.output_image_url ? postData.attributes.output_image_url : postData.attributes.image_url}
              alt={configJSON.YourImageText}
              style={webStyles.postImage as React.CSSProperties} />
          </a>}
        <Box mt={2}>
          <Typography
            variant="body1"
            component="p"
            style={webStyles.descrp}
          >
            {postData?.attributes?.description || configJSON.NoDescrpitionText}
          </Typography>
        </Box>
        <Box display={"flex"} alignItems={"center"} style={webStyles.locationBox}>
          <IconButton>
            <img src={location} />
          </IconButton>
          <Typography
            variant="body2"
            component="div" style={webStyles.locationText}>
            {postData?.attributes?.work_location || postData?.attributes?.location || configJSON.NoLocationText}
          </Typography>
        </Box>
        <Box display={"flex"} alignItems={"center"} justifyContent={'space-between'} flexWrap='wrap'>
          <Box display={"flex"} alignItems={'center'} justifyContent={'flex-start'}>
            <Box display={"flex"} alignItems={'center'}>
              <IconButton style={{ paddingLeft: '0px' }} data-test-id={`user-profile${index}`} onClick={() => this.goToUserProfile(postData.attributes?.account_id)}>
                <Avatar
                  src={postData?.attributes?.profile_pic || searchDefaultImage}
                  alt="Custom Profile Icon"
                  style={webStyles.profilePic}
                />
              </IconButton>
              <Typography
                variant="body2"
                style={webStyles.userName}
                component="div">
                {postData?.attributes?.user_name?.slice(0, 12)}
                {postData?.attributes?.user_name?.length < 12 ? "" : "..."}
              </Typography>
            </Box>
            <Box display={"flex"} alignItems={'center'}>
              <IconButton style={webStyles.iconCustomPadding}>
                <img src={time} />
              </IconButton>
              <Typography
                variant="body2"
                style={webStyles.userName}
                component="div">
                {getTimeFromNow(postData?.attributes?.created_at)} {configJSON.agoText}
              </Typography>
            </Box>
          </Box>
          <Box display={"flex"} justifyContent={"flex-end"}>
            {this.postLikeFlow(postData, index)}
            <Box data-test-id={`comment-view${index}`} style={webStyle.pointerClass} display={"flex"} alignItems={"center"} onClick={() => this.showComments(postData, index)}>
              <IconButton style={webStyles.iconCustomPadding}>
                <img src={comment} />
              </IconButton>
              <Typography variant="body2" component="div" style={webStyles.userName}>
                {postData?.attributes?.comments_count} {configJSON.CommentsText}
              </Typography>
            </Box>
          </Box>
          {this.addCommentFlow(index, postData)}
          {postData?.attributes?.openCommentsList && (
            <Box style={webStyles.commentBox}>
              <Typography style={webStyle.commentsHeader}>Comments ({this.state.totalComment})</Typography>
              {!this.state.commentsloading && this.state.comments.map((data) => {
                return (
                  <div style={webStyles.commentList} key={data.id}>
                    <Grid container justifyContent="space-between">
                      <Grid item lg={7}>
                        <Grid container alignItems="center">
                          <Avatar src={data?.attributes?.account?.profile_image || profileIcon} style={webStyle.commentImage} />
                          <Typography style={webStyle.commentUserName}>{data?.attributes?.account?.full_name}</Typography>
                          <Typography style={webStyle.commentTimeText}>
                            <div style={webStyle.timeDotIcon}></div>
                            {this.timetoSince(data?.attributes?.created_at)}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid item lg={4} style={webStyles.reportGrid}>
                        <MoreVertIcon data-test-id={`report-comment${index}`} style={webStyle.pointerClass} onClick={(event) => this.showReportComment(event, data?.id)}></MoreVertIcon>
                        {this.commentReportMenu(data, index, 'post')}
                      </Grid>
                    </Grid>
                    <Typography style={webStyle.commentValue}>{data?.attributes?.comment}</Typography>
                  </div>
                )
              })}
              {this.state.commentsloading &&
                <CircularProgress />}
              {
                !this.state.commentsloading && this.state.currentCommentPage !== Math.ceil(this.state.totalComment / 5) && (
                  <Typography align="center" style={webStyle.loadMore} data-test-id={`load-more2${index}`} onClick={() => this.getComment(postData?.attributes?.id)}>
                    <img src={refreshImg} style={webStyles.loadMoreIcon} />Load More
                  </Typography>
                )
              }

            </Box>
          )
          }
        </Box>
      </Box>
    )
  }

  postNoDataErrorMessage = () => {
    return (
      <Box display={'none'} color={'#000'} fontSize={'12px'}>
        <Typography>No post data found</Typography>
      </Box>
    );
  }

  storyErrorMessage = () => {
    return (
      <Box display={'none'} fontSize={'12px'} color={'#000'}>
        <Typography>Failed to load stories</Typography>
      </Box>
    );
  }

  storyNoDataErrorMessage = () => {
    return (
      <Box color={'#8E8E8E'} display={'none'} fontSize={'12px'}>
        <Typography>No stories data available</Typography>
      </Box>
    );
  }

  postAccessErrorMessage = () => {
    return (
      <Box color={'#909090'} display={'none'} fontSize={'16px'}>
        <Typography>You don't have access to view this post</Typography>
      </Box>
    );
  }

  commentsErrorMessage = () => {
    return (
      <Box display={'none'} color={'#AEAEAE'} fontSize={'13px'}>
        <Typography>Unable to load comments</Typography>
      </Box>
    );
  }

  commentsNoDataErrorMessage = () => {
    return (
      <Box display={'none'} color={'#AEAEAE'} fontSize={'13px'}>
        <Typography>No comments data found</Typography>
      </Box>
    );
  }

  commentsAccessErrorMessage = () => {
    return (
      <Box display={'none'} color={'red'} fontSize={'13px'}>
        <Typography>You cannot comment on this post</Typography>
      </Box>
    );
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const xs = this.state.searchVisible ? 12 : 8;
    return (
      <ThemeProvider theme={theme}>
        <Box style={webStyles.mainBox}>
          <Grid container>
            <Grid item xs={xs} style={webStyles.innerGrid}>
              {this.searchForPostAndUser()}

              {!this.state.searchVisible &&
                <Story2
                  navigation={undefined}
                  id={"dashboard_stories"}
                  onImageClick={(id: number) => this.handleChangeStoryImageId(id)}
                  storyImageId={this.state.storyImageId}
                  handleCloseStoryImage={this.handleCloseStoryImage}
                />
              }
              {!this.state.isLoading && this.state.postFromNotification &&
                <Button data-test-id="back-to-posts" onClick={this.handleLoadAllPosts} style={webStyles.backButtonStyle}>
                  <ArrowBackIcon style={webStyles.backIcon} /> Back
                </Button>
              }
              {!this.state.searchVisible && this.state.storyImageId === 0 &&
                <>
                  {this.state.dashboardData.length > 0 ?
                    this.state.dashboardData?.map((postData: PostData, index: number) => (
                      this.postCard(postData, index)
                    ))
                    :
                    <>
                      {!this.state.isLoading &&
                        <CustomMessage message="Looks like you don't have any post." />
                      }
                    </>
                  }
                </>
              }
              {this.state.isLoading && (
                <div style={webStyles.loadingStyle}>
                  <CircularProgress />
                </div>
              )}
              {!this.state.isLoading && this.state.postsPagination?.pagination.total_count !== 0 && this.state.postsPagination?.pagination.current_page !== Math.ceil(this.state.postsPagination?.pagination.total_count / 10) &&
                <Box marginTop={"20px"}>
                  {this.state.commentsloading && <Typography data-test-id="load-more-posts" onClick={() => this.loadMorePosts()} align="center" style={webStyle.loadMore}>
                    <img src={refreshImg} style={webStyles.loadMoreIcon} />Load More
                  </Typography>}
                </Box>
              }
            </Grid>
            <Loader loading={this.state.loading} />
            {this.state.reportPostModal &&
              <Dialog data-id="congrats-dialog" open={this.state.reportPostModal} fullWidth>
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="space-between"
                  style={webStylesLocal.dialogContainer}
                >
                  <Box display={"flex"} justifyContent={"space-between"}>
                    <Typography style={webStylesLocal.noteFont}>
                      {this.state.reportCommentModal ? configJSON.ReportCommentText : configJSON.ReportPostText}
                    </Typography>
                    <CloseIcon
                      data-test-id="reportcloseBtn"
                      style={webStylesLocal.closeIcon}
                      onClick={() => this.closeReportModal()}
                    />
                  </Box>
                  <Typography style={webStylesLocal.subHeader}>
                    {configJSON.ReportSubHeaderText}
                  </Typography>
                  <Grid container>
                    {this.state.reportTags.map((tagData: TagsData, index: number) => {
                      return (
                        <>
                          <Button
                            key={index}
                            data-test-id={`tagBtn${index}`} variant="outlined"
                            style={this.getTagColor(parseInt(tagData.id)) ? webStylesLocal.SelectedTagBtn as React.CSSProperties : webStylesLocal.TagBtn as React.CSSProperties}
                            onClick={() => this.addSelectedTags(parseInt(tagData.attributes.id))}>
                            <Typography style={webStylesLocal.addIcon}>
                              {this.getTagColor(parseInt(tagData.id)) ? <img src={checkImg} /> : <img src={addImg} />}</Typography>
                            <Typography style={this.getTagColor(parseInt(tagData.id))
                              ? webStylesLocal.selectedTagName as React.CSSProperties :
                              webStylesLocal.tagName as React.CSSProperties}>{tagData.attributes.name}</Typography>
                          </Button>
                        </>
                      )
                    })}
                  </Grid>
                  <Box>
                    <Typography style={webStylesLocal.label}>{configJSON.ReportDescriptionLable}</Typography>
                    <textarea
                      style={webStylesLocal.textareaStyle}
                      placeholder="Description"
                      value={this.state.reportdescription}
                      onChange={(e: { target: { value: string } }) => this.handledescriptionChange(e)}
                      data-test-id="handleTextAreaChange"
                      className="textarea"
                    />
                    {
                      this.state.isDescriptionErr && (
                        <Typography style={webStylesLocal.errorText}>{configJSON.errorTextMsg}</Typography>
                      )
                    }
                  </Box>
                  <Box display={"flex"} justifyContent={"flex-end"} >
                    <Button data-test-id="reportbtn" style={webStylesLocal.reportBtn as React.CSSProperties} onClick={() => this.handleReportApiCall()}>
                      {configJSON.ReportBtnText}
                    </Button>
                  </Box>
                </Box>
              </Dialog>
            }
            {this.state.postIsReported &&
              <Dialog data-id="congrats-dialog" open={this.state.postIsReported} fullWidth>
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="space-between"
                  style={webStylesLocal.dialogContainer}
                >
                  <Box display={"flex"} justifyContent={"flex-end"}>
                    <CloseIcon
                      data-test-id="reportedCloseBtn"
                      style={webStylesLocal.closeIcon}
                      onClick={() => this.closeReportModal()}
                    />
                  </Box>
                  <Box display={"flex"} justifyContent={"center"}>
                    <img src={SuccessImage} style={webStyles.successImg} />
                  </Box>
                  <Typography style={webStylesLocal.ReportedFont} align='center'>
                    {this.state.reportCommentModal ? configJSON.CommentReportedText : configJSON.PostReportedText}
                  </Typography>
                  <Typography style={webStylesLocal.subHeader2}>
                    {configJSON.ReportedSubHeaderText}
                  </Typography>
                  <Button data-test-id="okayBtn" style={webStylesLocal.okayBtn as React.CSSProperties} variant='outlined' onClick={this.closeReportModal}>
                    {configJSON.OkayBtnText}
                  </Button>
                </Box>
              </Dialog>
            }
            {!this.state.searchVisible &&
              <Grid item xs={4}>
                <Button
                  style={webStyles.customButton}
                  onClick={() => { this.setShowPostPopUp(true); this.createEmptyPost(); }}
                  data-test-id="setShowPostPopUp"
                >
                  <span style={webStyles.customButtonText as React.CSSProperties}>
                    + Create a Post
                  </span>
                </Button>
                <List id="test" style={webStyles.listBox}>
                  <ListSubheader style={webStyles.headB}>
                    <h3>Broadcast Messages</h3>
                    <p style={webStyles.headp}>See All</p>
                  </ListSubheader>
                  <h3 style={webStyles.commingSoonText as React.CSSProperties}>Coming Soon</h3>
                </List>

                <List style={webStyles.listBox}>
                  <ListSubheader id="subh" style={webStyles.headB}>
                    <h3>Community</h3>
                    <p style={webStyles.headp}>See All</p>
                  </ListSubheader>
                  <h3 style={webStyles.commingSoonText as React.CSSProperties}>Coming Soon</h3>
                </List>
              </Grid>}
          </Grid>
        </Box>
        {this.state.showPostPopUp && (
          <PostCreationPopUp
            data-test-id="post-creation-popup"
            setShowPostPopUp={this.setShowPostPopUp}
            postSettings={this.state.postSettings}
            loading={this.state.loading}
            aiImageprocessing={this.state.aiImageprocessing}
            createPost={this.createPost}
            userName={this.state.userName}
            postCreationFinished={this.state.postCreationFinished}
            buLocationCategory={this.state.buLoaction}
            machineWorkLocation={this.state.machineWorkLocation}
            machineChangeStatus={this.getMachineWorkLocationAPI}
            operationIdentificationsChange={this.getOperationsIdentifications}
            operationIdentificationsData={this.state.operationsIdentifications}
            loaderData={this.state.postDataLoading}
            getImageFile={this.handleUploadImage}
            postDescription={this.state.postDescription}
            showAiTag={this.state.showAiTag}
            aiProcessingError={this.state.aiProcessingError}
            resetAiErrorMessage={() => this.setState({ aiImageprocessing: false, aiProcessingError: null })}
          />
        )}
        <Loader loading={this.state.loader} />
        {this.postNoDataErrorMessage()}
        {this.storyErrorMessage()}
        {this.storyNoDataErrorMessage()}
        {this.postAccessErrorMessage()}
        {this.commentsErrorMessage()}
        {this.commentsNoDataErrorMessage()}
        {this.commentsAccessErrorMessage()}
      </ThemeProvider>
      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyles = {
  reportGrid: {
    justifyContent: 'flex-end',
    display: 'flex'
  },
  commentList: {
    padding: '10px',
    marginBottom: '10px',
    background: '#F8F8F8'
  },
  commentBox: {
    width: '99%',
    marginLeft: '10px',
    marginTop: '10px',
  },
  loadMoreIcon: {
    width: '15px',
    height: '15px',
    marginRight: '10px'
  },
  successImg: {
    height: '70px',
    width: '70px'
  },
  reportMenuWidth: {
    width: "110px",
  },
  reportMenuItem: {
    fontFamily: 'ClotherRegular',
    fontSize: '14px',
    lineHeight: '22px',
    color: '#2B2A29',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 'max-content',
  },
  postImage: {
    width: '100%',
    height: 300,
    objectFit: 'cover',
    marginTop: 8,
    borderRadius: "8px",
    cursor: 'pointer'
  },
  postUserName: {
    fontSize: "18px",
    fontFamily: "ClotherBold"
  },
  profilePic: {
    fontSize: "14px",
    height: "26px",
    width: "26px",
    border: "1px solid #8735E1"
  },
  locationText: {
    marginRight: 75,
    fontSize: "16px",
    color: '#A1A1A1',
    fontFamily: 'ClotherRegular'
  },
  locationBox: {
    margin: '11.5px 5px 11.5px 0px',
    background: "#f5f5f5",
    width: "fit-content",
    borderRadius: "4px",
    cursor: 'pointer'
  },
  userName: {
    color: '#A1A1A1',
    fontFamily: 'ClotherRegular'
  },
  attachments: {
    paddingRight: "5px"
  },
  iconBtn: {
    position: 'absolute',
    right: "0px",
    borderRadius: "0",
    padding: "10px",
    margin: "2px",
  },
  customIcon: {
    marginRight: 8,
    cursor: "pointer",
    height: "46px",
    width: "46px"
  },
  okBtn: {
    width: '90px',
    height: '40px',
    borderRadius: '8px',
    background: '#8735E1',
    fontFamily: 'ClotherBold',
    fontSize: '20px',
    lineHeight: '22px',
    color: '#FFFFFF',
    marginTop: '30px',
    textTransform: 'none'
  },
  errorMsg: {
    fontFamily: 'ClotherRegular',
    fontSize: '16px',
    color: 'red'
  },
  dialogContainer: {
    minWidth: '300px',
    padding: "30px",
    borderRadius: "8px",
  },
  innerGrid: {
    paddingRight: "30px"
  },
  mainBox: {
    margin: "30px",
    backgroundColor: "#eee",
    fontFamily: 'ClotherRegular'
  },
  textRight: {
    textAlign: "right"
  },
  avatarImage: {
    width: 24,
    height: 24,
    cursor: "pointer"
  },
  commingSoonText: {
    textAlign: 'center',
    fontFamily: 'ClotherBold'
  },
  avatarBox: {
    minWidth: "36px"
  },
  RedText: {
    color: "red"
  },
  postLocationBox: {
    margin: "8px 0px"
  },
  moreIcon: {
    color: "#A1A1A1"
  },
  postContentBox: {
    padding: "16px"
  },
  pagination: {
    marginTop: "16px"
  },
  closeIcon: {
    color: "#A1A1A1",
    paddingRight: "10px"
  },
  imageStyles: {
    width: "40px",
  },
  cursorPoint: {
    cursor: "pointer"
  },
  topbar: { height: "100px", padding: "0 40px" },
  sidebar: {
    background: "#FFF",
    boxShadow: "4px 0px 4px 0px rgba(0, 0, 0, 0.02)",
  },
  icon: {
    fontFamily: "clotherbold",
    backgroundColor: "#eee",
    borderRadius: "50%",
    padding: "9px",
    color: "#A1A1A1",
    fontSize: "12px",
  },
  descrp: {
    fontSize: "18px",
    fontFamily: "clotherRegular",
    color: "#828282",
  },

  listBox: {
    borderRadius: "8px",
    background: "#fff",
    marginBottom: "25px",
  },
  headB: {
    display: "flex",
    justifyContent: "space-between",
    borderBottom: "1px solid #eee",
    lineHeight: "20px",
    fontSize: "18px",
    fontFamily: "Clotherbold",
    color: "#18181E",
    alignItems: "center",
    borderRadius: "8px 8px 0px 0px",
  },
  headp: {
    fontSize: "12px",
    color: "#9519EF",
    cursor: "pointer",
  },
  customButton: {
    display: "flex",
    width: "100%",
    height: "46px",
    padding: "10px",
    justifyContent: "center",
    alignItems: "center",
    flexShrink: 0,
    borderRadius: "8px",
    background: "#8735E1",
    marginBottom: "20px",
  },
  customButtonText: {
    color: "#FFF",
    fontFamily: "Clotherbold",
    fontSize: "20px",
    fontWeight: 700,
    lineHeight: "22px",
    letterSpacing: "-0.408px",
    textTransform: "none",
  },
  loadingStyle: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "auto",
    margin: "20px 0px 20px 0px",
  },
  iconCustomPadding: {
    marginLeft: "12px",
    padding: "8px",
  },
  backButtonStyle: {
    background: "#DDDDDD",
    textTransform: "capitalize",
    height: "30px",
    color: "#828282",
    borderRadius: "6px",
    padding: "10px 12px",
    margin: "20px 10px 0px 0px",
    fontSize: "14px",
    fontWeight: 400,
  } as const,
  backIcon: {
    fontSize: '16px',
    marginRight: '8px',
  },
  errorBlock: {
    width: '100%',
    height: '100%',
    display: 'none',
    color: '#000',
  }
};
export const WrapperSearch = styled(Box)({
  margin: "0px 0px 20px 0px",
  backgroundColor: "#FFFFFF",
  color: "#A1A1A1",
  borderRadius: "8px",
  fontFamily: "Clother",
  fontSize: "14px",
  fontWeight: 400,
  "& .searchTextField": {
    width: "100%",
    height: "46px",
    borderRadius: "8px",
    paddingLeft: "10px",
    color: "#A1A1A1",
  },
  "& .MuiInputBase-inputTypeSearch": {
    padding: "10px 10px 10px 0px"
  },
  "&. MuiInputBase": {
    height: "26px",
  },
  "& .searchIconStyle": {
    color: "#A1A1A1",
    paddingRight: "10px",
    width: "20px"
  },
  "& .closeIconStyle": {
    cursor: "pointer"
  },
})
export const ButtonWrapper = styled(Box)({
  fontFamily: "ClotherRegular",
  "& .buttonStyle": {
    background: "#DDDDDD",
    textTransform: "capitalize",
    height: "30px",
    color: "#828282",
    borderRadius: "6px",
    padding: "10px 12px",
    marginRight: "10px",
    fontSize: "14px",
    fontWeight: 400,
  },
  "& .activeButtonStyle": {
    fontWeight: 700,
    background: "#8735E1",
    color: "#fff",
  },
  "& .foundText": {
    fontFamily: "ClotherRegular",
    color: "#2B2A29",
    fontSize: "17px",
    fontWeight: 400,
    margin: "16px 0px"
  },
  "& .userNameText": {
    color: "#2B2A29",
    fontSize: "17px",
    fontWeight: 400,
    margin: "4px 4px 4px 16px"
  },
  "& .postNameText": {
    color: "#A1A1A1",
    fontSize: "17px",
    fontWeight: 400,
    fontFamily: "ClotherRegular",
    margin: "0px 12px 0px 0px",
  },
  "& .locationText": {
    color: "#A1A1A1",
    fontFamily: "ClotherRegular",
    fontSize: "17px",
    fontWeight: 400,
    margin: "4px",
  },
  "& .trimWords": {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: "nowrap",
    maxWidth: '100%'
  },

  "& .trimWordsUser": {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: "nowrap",
    maxWidth: '80%'
  },
  "& .postTitleText": {
    color: "#2B2A29",
    fontFamily: "ClotherBold",
    fontSize: "17px",
    fontWeight: 700,
    margin: "4px 4px 4px 16px"
  },
  "& .mainContainerGrid": {
    padding: "8px",
    borderBottom: "1px solid lightgray"
  },
  "& .postDescription": {
    fontFamily: "ClotherRegular",
    fontWeight: 400,
    fontSize: "17px",
    color: "#828282",
    margin: "8px 0px",
    wordWrap: "break-word"
  },
  "& .contentBox": {
    backgroundColor: "#FFFFFF",
    borderRadius: "8px"
  },
  "& .postContentBox": {
    backgroundColor: "#FFFFFF",
    border: "1px solid lightgray",
    borderRadius: "8px",
    marginBottom: "16px"
  },
  "& .flexCenter": {
    display: "flex",
    alignItems: "center"
  },
  "& .flexEnd": {
    display: "flex",
    justifyContent: "flex-end"
  },
  "& .imageStyle": {
    width: "40px",
    height: "40px",
    border: "1px solid lightgray",
    borderRadius: "50%",
  },
  "& .rightImageContainer": {
    backgroundColor: "EEEEEE",
    borderRadius: "8px",
    padding: "8px"
  },
  "& .postLocationContainer": {
    backgroundColor: "EEEEEE",
    borderRadius: "4px",
    padding: "4px"
  },
  "& .PostTitleImageStyle": {
    width: "50px",
    height: "50px",
    border: "1px solid lightgray",
    borderRadius: "50%",
  },
  "& .PostBelowImageStyle": {
    width: "30px",
    height: "30px",
    border: "1px solid #8735E1",
    borderRadius: "50%",
  },
  "& .postIconstyle": {
    marginRight: "10px",
    color: "#A1A1A1"
  }
})
// Customizable Area End
