import React from "react";

import {
  // Customizable Area Start
  Container,
  Box,
  Typography,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";

const theme = createTheme({
    palette: {
        primary: {
            main: "#fff",
            contrastText: "#fff",
        },
    },
    typography: {
        h6: {
            fontWeight: 500,
        },
        subtitle1: {
            margin: "20px 0px",
        },
    },
});
import RedemptionHistoryController, {
    Props,
} from "./RedemptionHistoryController";
// Customizable Area End

export default class RedemptionHistory extends RedemptionHistoryController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
      <Box sx={webStyle.wrapper}>
          <Container style={webStyle.mainWrapperAuto} maxWidth={"xl"}>
                    <Box style={webStyle.redemptionWrapper}>
                        <Typography style={webStyle.redemptionSubHeading}>Profile</Typography>
                        <Typography>{'>'}</Typography>
                        <Typography style={webStyle.redemptionSubHeading} data-test-id="gotoRewards" onClick={()=>{this.gotoReward()}}>Rewards</Typography>
                        <Typography>{'>'}</Typography>
                        <Typography style={webStyle.redemptionHeading}>Redemption History</Typography>
                    </Box>
              <Typography style={webStyle.mainHeading}>Redemption History</Typography>

              <Box sx={webStyle.mainAutoBox}>
                  <Box style={webStyle.redemptionRow}>
                      <Box>
                          <Box style={webStyle.redemptionPointsCardRow}>
                              <Box>
                                  <Typography style={webStyle.redemptionPointsTitle}>Amazon voucher worth ₹200 Redeemed</Typography>
                                  <Typography style={webStyle.redemptionPointsDate}>30/10/2023</Typography>
                              </Box>
                              <Typography align="right" style={webStyle.redemptionPointsCount}>50 points</Typography>
                          </Box>
                          <Box style={webStyle.redemptionPointsCardRow}>
                              <Box>
                                  <Typography style={webStyle.redemptionPointsTitle}>Amazon voucher worth ₹200 Redeemed</Typography>
                                  <Typography style={webStyle.redemptionPointsDate}>26/10/2023</Typography>
                              </Box>
                              <Typography align="right" style={webStyle.redemptionPointsCount}>150 points</Typography>
                          </Box>
                          <Box style={webStyle.redemptionPointsCardRow}>
                              <Box>
                                  <Typography style={webStyle.redemptionPointsTitle}>Amazon voucher worth ₹200 Redeemed</Typography>
                                  <Typography style={webStyle.redemptionPointsDate}>26/10/2023</Typography>
                              </Box>
                              <Typography align="right" style={webStyle.redemptionPointsCount}>50 points</Typography>
                          </Box>
                          <Box style={webStyle.redemptionPointsCardRow}>
                              <Box>
                                  <Typography style={webStyle.redemptionPointsTitle}>Amazon voucher worth ₹200 Redeemed</Typography>
                                  <Typography style={webStyle.redemptionPointsDate}>22/10/2023</Typography>
                              </Box>
                              <Typography align="right" style={webStyle.redemptionPointsCount}>50 points</Typography>
                          </Box>
                          <Box style={webStyle.redemptionPointsCardRow}>
                              <Box>
                                  <Typography style={webStyle.redemptionPointsTitle}>Amazon voucher worth ₹200 Redeemed</Typography>
                                  <Typography style={webStyle.redemptionPointsDate}>10/10/2023</Typography>
                              </Box>
                              <Typography align="right" style={webStyle.redemptionPointsCount}>50 points</Typography>
                          </Box>
                      </Box>
                  </Box>
              </Box>
          </Container>
      </Box>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
    wrapper: {
        width: '100%',
        backgroundColor: 'lightgray',
        padding: '20px 0',
        boxSizing: 'border-box',
        height: 'calc(100vh - 100px)',
        overflow: 'auto',
        fontFamily: "ClotherRegular"

    },
    mainHeading: {
        margin: '20px 0',
        color: "#2B2A29",
        leadingTrim: "both",
        textEdge: "cap",
        fontSize: "26px",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "18px",
        marginBottom: '30px',
        fontFamily: "ClotherBold"
    },
    mainWrapperAuto: {
        padding: '0 !important'
    },
    mainAutoBox: {
        background: '#fff',
        width: '100%',
        boxSizing: 'border-box',
        borderRadius: '8px',
    },
    redemptionWrapper: {
        display: 'flex',
        gap: '10px'
    },
    redemptionSubHeading: {
        fontWeight: 700,
        textEdge: "cap",
        fontFamily: 'ClotherRegular',
        fontSize: "16px",
        lineHeight: '22px',
        paddingBottom: '17px',
        fontStyle: "normal",
        cursor: "pointer",
        leadingTrim: "both",
        color: "#A1A1A1",

    },
    redemptionHeading: {
        color: "#8735E1",
        leadingTrim: "both",
        textEdge: "cap",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "22px",
        fontFamily: "ClotherBold"

    },
    redemptionRow: {
        width: '100%',
    },
    redemptionPointsCardRow: {
        padding: '20px 30px',
        borderBottom: '1px solid lightgray',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    redemptionPointsTitle: {
        color: "#2B2A29",
        fontSize: "18px",
        fontStyle: "normal",
        fontWeight: 400,
        fontFamily: "ClotherRegular"
    },
    redemptionPointsDate: {
        color: "#A1A1A1",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 400,
        fontFamily: "ClotherRegular"

    },
    redemptionPointsCount: {
        color: "#F30202",
        textAlign: "right" as "right",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 700,
        fontFamily: "ClotherBold"

    }
};
// Customizable Area End
